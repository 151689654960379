import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";
const copyrightLinks = [
  {
    title: "Terms of Use",
    href: "/",
  },
  {
    title: "Privacy Policy",
    href: "/",
  },
];

const serviceMenu = [
  {
    title: "UI/UX design",
    href: "/service/ui-ux-design",
  },
  {
    title: "Frontend Development",
    href: "/service/frontend-development",
  },
  {
    title: "Backend Development",
    href: "/service/backend-development",
  },
  {
    title: "Mobile App Development",
    href: "/service/mobile-app-development",
  },
  {
    title: "Cloud Services",
    href: "/service/cloud-services",
  },
  {
    title: "Digital Marketing",
    href: "/service/digital-marketing",
  },
];

const resourcesMenu = [
  {
    title: "Blog Posts & Articles",
    href: "https://thetechshine.com/blog/",
    isExternal: true,
  },
  {
    title: "Portfolio & Case Studies",
    href: "/portfolio",
    isExternal: false,
  },
  {
    title: "Support & FAQ",
    href: "/contact",
    isExternal: false,
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/footer_logo.svg"
                  logoAlt="Logo"
                  text="Welcome to our company where we build your dreams into reality. We are a team of talented professionals who are committed to delivering high-quality services to our clients."
                />
                {/* <SocialWidget /> */}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={resourcesMenu} menuHeading="Resources" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2025 TheTechShine IT Solutions
            </Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
